@font-face {
  font-family: 'icons';
  src: url('../../assets/icons/icons.eot');
  src: url('../../assets/icons/icons.eot?#iefix') format('eot'),
    url('../../assets/icons/icons.woff') format('woff'),
    url('../../assets/icons/icons.ttf') format('truetype'),
    url('../../assets/icons/icons.svg#icons') format('svg');
}

.icon:before {
  font-family: 'icons';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  /* speak: none; only necessary if not using the private unicode range (firstGlyph option) */
  text-decoration: none;
  text-transform: none;
}

.icon-checkbox-checked:before {
  content: '\E001';
}

.icon-checkbox-indeterminate:before {
  content: '\E002';
}

.icon-close-round:before {
  content: '\E003';
}

.icon-facebook-filled:before {
  content: '\E004';
}

.icon-facebook:before {
  content: '\E005';
}

.icon-radio-off:before {
  content: '\E006';
}

.icon-radio-on:before {
  content: '\E007';
}

.icon-sort-asc:before {
  content: '\E008';
}

.icon-sort-desc:before {
  content: '\E009';
}
