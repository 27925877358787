@import '../../../scss/utils/getter.scss';
@import '../../../scss/utils/mixins.scss';
@import '../../../scss/var/colors.scss';
@import '../../../scss/var/screensizes.scss';
@import '../../../scss/var/transitions.scss';
@import '../../../scss/var/typography.scss';

.nav {
  @include xs {
    display: flex;
    width: 300vw;
    bottom: -100vh;
    left: -100vw;
    background-image: linear-gradient(
      -60deg,
      $color-grey-gradient-1 0%,
      $color-grey-gradient-2 100%
    );
    flex-direction: column;
    position: fixed;
    padding-top: 1.5em;
    padding-bottom: 6em;
    box-shadow: 0px -15px 0px -2px rgba(250, 145, 58, 0.2);
    border-radius: 100% 100% 0 0;
    &-open {
      animation: menuOpen 250ms ease-out forwards;
    }
  }
  @include md {
    position: relative;
    bottom: 0;
    left: 0;
    background: none;
    box-shadow: none;
    flex-direction: row;
    border-radius: 0;
    display: flex;
    justify-content: space-around;
    width: 100%;
    max-width: 700px;
    padding: 0;
    &-open {
      animation: none;
    }
  }
  .nav__item {
    box-sizing: border-box;
    color: $color-grey-gradient-2;
    display: inline-flex;
    font-weight: 600;
    @include xs {
      align-items: center;
      color: $color-white;
      display: flex;
      font-size: 18px;
      justify-content: center;
      line-height: 8vh;
      opacity: 0.8;
      &-active {
        color: $color-orange;
        box-shadow: inset -5px 0px 0px 0px $color-orange;
      }
    }
    @include md {
      color: $color-black;
      display: inline-flex;
      font-size: 14px;
      line-height: 60px;
      opacity: 1;
      padding-right: 0;
      &-active {
        box-shadow: inset 0px -4px 0px 0px $color-orange;
      }
    }
    .icon {
      @include xs {
        color: $color-white;
      }
      @include md {
        color: $color-fb;
      }
      display: inline-flex;
      font-size: 16px;
      padding-right: 0.5em;
    }
  }
  .mobileSignup {
    @include xs {
      display: flex;
    }
    @include md {
      display: none;
    }
    padding-top: 1em;
    padding-bottom: 2em;
    justify-content: center;
  }
}
