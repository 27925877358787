h2 {
  opacity: 0.95;
  font-family: $font-title;
  line-height: 48px;
  color: $color-black;
  letter-spacing: 1.98px;
  position: relative;
  padding-left: 48px;
  text-transform: uppercase;
  font-size: 2.4em;
  @include xs {
    padding-bottom: 0;
  }
  @include md {
    padding-bottom: 1em;
  }
  &:before {
    top: 0px;
    left: 0;
    content: '';
    white-space: pre;
    position: absolute;
    width: 0.5em;
    height: 1em;
    background: linear-gradient(
      45deg,
      $color-orange-gradient-1 0%,
      $color-orange-gradient-2 100%
    );
    transform: skewY(-30deg) rotate(30deg);
    border-radius: 4px;
  }
}

h3 {
  opacity: 0.95;
  font-family: $font-title;
  font-size: 1.2em;
  line-height: 1.2em;
  color: $color-black;
  letter-spacing: 0.98px;
  padding-bottom: 1em;
  text-transform: uppercase;
}

p {
  margin-bottom: 2em;
  line-height: 1.9em;
  font-family: $font-body;
  opacity: 0.9;
}
