@import '../../../scss/utils/mixins.scss';
@import '../../../scss/var/colors.scss';
@import '../../../scss/var/screensizes.scss';
@import '../../../scss/var/typography.scss';

#about {
  background-image: url('parti_asset.png'),
    linear-gradient(
      -60deg,
      $color-grey-gradient-1 0%,
      $color-grey-gradient-2 100%
    );
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: contain;
  @include lg {
    background-size: contain;
  }
  .title {
    color: $color-white;
  }
  .content {
    color: $color-white;
    p {
      margin-bottom: 2em;
    }
    @include xs {
      grid-column: 12 span;
    }
    @include sm {
      grid-column: 10 span;
    }
    @include md {
      grid-column: 5 span;
    }
    @include lg {
      grid-column: 6 span;
    }
    @include xl {
      grid-column: 7 span;
    }
  }
  .logos {
    @include xs {
      grid-column: 12 span;
    }
    @include sm {
      grid-column: 10 span;
    }
    @include md {
      grid-column: 6 span;
    }
    @include lg {
      grid-column: 5 span;
    }
    @include xl {
      grid-column: 4 span;
    }
    height: min-content;
    background: $color-white;
    text-align: center;
    position: relative;
    box-shadow: -20px 20px 0px -2px rgba(250, 145, 58, 0.1);
    @include md {
      &:before {
        content: '';
        background: url(cara.png);
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        left: -100px;
        top: 100px;
        height: 150px;
        width: 133px;
      }
    }
    &__station {
      width: 60px;
      height: 60px;
      padding: 18px;
    }
  }
}
