@import '../../../../scss/utils/mixins.scss';
@import '../../../../scss/var/colors.scss';
@import '../../../../scss/var/screensizes.scss';
@import '../../../../scss/var/typography.scss';

.feed__winner {
  margin-left: 24px;
  font-family: $font-body;
  background: $color-white;
  box-shadow: 0 0 7px 0 $color-light-grey;
  border-radius: 4px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  padding: 12px;
  position: relative;
  width: 86%;
  &__date {
    padding-bottom: 4px;
    font-size: 14px;
    color: $color-mid-grey;
  }
  &__post {
    font-size: 16px;
    font-style: italic;
    color: $color-black;
    line-height: 24px;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: -24px;
    height: 12px;
    width: 12px;
    background: $color-orange;
    display: block;
    border-radius: 100%;
    border: 4px solid $color-white;
  }
}
