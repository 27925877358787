@import '../../../scss/utils/getter.scss';
@import '../../../scss/utils/mixins.scss';
@import '../../../scss/var/colors.scss';
@import '../../../scss/var/screensizes.scss';
@import '../../../scss/var/transitions.scss';
@import '../../../scss/var/typography.scss';

.btnMenu {
  @include xs {
    flex: 0 0 auto;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    padding: 0.75em;
    cursor: pointer;
    position: fixed;
    bottom: 36px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 900;
    background: $color-white;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.16);

    &__hamburger,
    &__cross {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &__hamburger span {
      display: block;
      width: 18px;
      height: 2px;
      margin-bottom: 3px;
      overflow: hidden;
      position: relative;
    }
    &__hamburger span:last-child {
      margin: 0;
    }
    &__hamburger span:before,
    &__hamburger span:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: $color-black;
      transform: translateX(-200%);
      transition: transform ease 300ms;
    }
    &__hamburger span:after {
      transform: translateX(0);
    }
    &__hamburger span:nth-child(2):before,
    &__hamburger span:nth-child(2):after {
      transition-delay: 75ms;
    }
    &__hamburger span:last-child:before,
    &__hamburger span:last-child:after {
      transition-delay: 150ms;
    }
    &:hover {
      &__hamburger span:before {
        transform: translateX(0);
      }
    }
    &:hover {
      &__hamburger span:after {
        transform: translateX(200%);
      }
    }
    &__active {
      .btnMenu__hamburger span:before {
        transform: translateX(100%);
      }
    }
    &__active {
      .btnMenu__hamburger span:after {
        transform: translateX(200%);
      }
    }
    &__cross span {
      display: block;
      width: 18px;
      height: 2px;
      background-color: $color-black;
      transform: translateY(50%) rotate(45deg) scaleX(0);
      transition: transform ease 200ms;
    }
    &__cross span:last-child {
      transform: translateY(-50%) rotate(-45deg) scaleX(0);
    }
    &__active {
      .btnMenu__cross span {
        transition-delay: 450ms;
        transform: translateY(50%) rotate(45deg) scaleX(1);
      }
    }
    &__active {
      .btnMenu__cross span:last-child {
        transform: translateY(-50%) rotate(-45deg) scaleX(1);
      }
    }
  }
  @include md {
    display: none;
  }
}
