@import '../../../scss/utils/getter.scss';
@import '../../../scss/var/colors.scss';
@import '../../../scss/var/transitions.scss';

.select {
  &__container {
    width: 100%;
    position: relative;
    color: $color-black;
    border-radius: 4px;
    &-error {
      border: solid 2px $color-red;
    }
    .helper {
      &--display,
      &--default,
      &--error,
      &--disabled {
        user-select: none;
        position: absolute;
        font-size: 12px;
        bottom: -18px;
        left: 16px;
        transition: opacity get-animation(speed, controllers)
            get-animation(ease, default),
          color get-animation(speed, controllers) get-animation(ease, default);
      }

      &--default {
        color: $color-mid-grey;
        opacity: 0;
      }

      &--display {
        color: $color-orange;
        opacity: 1;
      }

      &--error {
        color: $color-red;
      }

      &--disabled {
        color: $color-mid-grey;
      }
    }
  }

  &__input {
    width: 100%;
    height: 48px;
    cursor: pointer;
    border-radius: 4px;
    text-align: left;
    color: $color-black;
    line-height: 48px;
    padding-left: 38px;
    padding-right: 42px;
    font-size: 14px;
    border: solid 1px transparent;
    box-sizing: border-box;
    background-color: mix($color-light-grey, $color-white, 50%);
    justify-content: space-around;

    &-value {
      height: 48px;
      display: inline-flex;
      overflow-x: hidden;
      overflow-y: hidden;
      overflow-wrap: break-word;
      text-overflow: ellipsis;
    }
    &-label {
      @extend .select__input-value;
      color: #7f7f7f;
    }

    .icon {
      font-size: 16px;
      line-height: 48px;
      user-select: none;
      pointer-events: none;
      position: absolute;
      right: 22px;
      color: $color-mid-grey;
    }

    .color {
      top: 17px;
      left: 16px;
      height: 15px;
      width: 15px;
      background-color: black;
      border-radius: 15px;

      &-value1 {
        background-image: linear-gradient(30deg, #da1379 0%, #6d3891 100%);
      }

      &-value2 {
        background-image: linear-gradient(90deg, #ffc038 0%, #f28a15 100%);
      }
    }

    .left-icon {
      left: 12px;
      color: $color-black;
    }

    &-filled {
      color: $color-black;
      background-color: $color-white;
      border: solid 1px $color-mid-grey;
      line-height: 46px;
      padding-left: 37px;
    }
    &-open {
      color: $color-black;
      background-color: $color-white;
      border: solid 2px $color-orange;
      line-height: 46px;
      padding-left: 37px;
    }
    &-error {
      color: $color-black;
      background-color: $color-white;
      border: none;
      line-height: 46px;
      padding-left: 37px;
    }
  }

  &__dropdown {
    &__container {
      width: 99%;
      background-color: $color-white;
      font-size: 14px;
      margin-left: 1%;
      border-radius: 3px;
      max-height: 60vh;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.23);
      z-index: 200;
      position: absolute;
      top: 48px;
      left: -2px;
      overflow-x: hidden;
    }

    &__row {
      box-sizing: border-box;
      text-align: left;
      justify-content: space-between;
      width: 100%;
      height: 48px;
      line-height: 48px;
      cursor: pointer;
      padding-left: 13px;
      padding-right: 16px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      &:first-letter {
        display: block;
        text-transform: uppercase;
      }
      &-active {
        font-weight: 600;

        .icon {
          color: $color-orange;
          float: right;
        }
      }

      &:hover {
        background-color: mix($color-light-grey, $color-white, 40%);
      }
    }
  }
}
