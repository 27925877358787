@import '../../../scss/utils/getter.scss';
@import '../../../scss/var/colors.scss';
@import '../../../scss/var/transitions.scss';

.MultiSelect {
  &__container {
    width: 100%;
    position: relative;
    color: $color-black;
    &--error {
      .MultiSelect__control {
        background-color: $color-white !important;
        border: 2px solid $color-red !important;
      }
    }
    &--has-values {
      .MultiSelect__control {
        background-color: $color-white !important;
        border: 1px solid $color-mid-grey !important;
      }
    }
  }
  &__control {
    width: 100%;
    min-height: 48px !important;
    cursor: pointer !important;
    border-radius: 4px;
    text-align: left;
    color: $color-black;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 4px !important;
    font-size: 14px;
    border: solid 1px transparent;
    box-sizing: border-box;
    background-color: mix($color-light-grey, $color-white, 50%) !important;
    border: none !important;
    box-sizing: border-box;
    &--is-focused {
      color: $color-black;
      background-color: $color-white !important;
      border: solid 2px $color-orange !important;
      box-shadow: none !important;
      .MultiSelect__dropdown-indicator {
        transform: rotate(180deg);
      }
    }
  }
  &__indicator {
    &-separator {
      display: none !important;
    }
  }
  &__dropdown-indicator {
    padding: 0 !important;
    font-size: 16px;
    -webkit-user-select: none;
    user-select: none;
    pointer-events: none;
    position: absolute;
    right: 22px;
    color: $color-mid-grey !important;
    &:before {
      font-family: 'icons';
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-style: normal;
      font-feature-settings: normal;
      font-variant: normal;
      font-weight: normal;
      text-decoration: none;
      text-transform: none;
      content: '\E009';
    }
    svg {
      display: none;
    }
  }
  &__clear-indicator {
    margin-right: 32px;
  }
  &__menu {
    margin-top: 1px !important;
  }
  &__option {
    font-size: 14px !important;
    box-sizing: border-box;
    text-align: left;
    justify-content: space-between;
    width: 100%;
    height: 48px;
    line-height: 48px;
    padding: 0 16px 0 12px !important;
    &:first-letter {
      display: block;
      text-transform: uppercase;
    }
    &:hover {
      cursor: pointer;
    }
    &--is-focused {
      color: $color-orange !important;
      background-color: mix($color-light-grey, transparent, 30%) !important;
    }
  }
  &__menu-notice {
    &--no-options {
      font-size: 14px;
    }
  }
  &__multi-value {
    background-color: $color-orange !important;
    &__label {
      color: $color-white !important;
      display: inline-flex;
      line-height: 24px;
    }
    &__remove svg {
      stroke: $color-white !important;
      fill: $color-white !important;
      display: inline-flex;
      line-height: 24px;
    }
  }
}
