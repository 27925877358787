@import '../../scss/utils/getter.scss';
@import '../../scss/utils/mixins.scss';
@import '../../scss/var/colors.scss';
@import '../../scss/var/screensizes.scss';
@import '../../scss/var/typography.scss';

#legal {
  @include xs {
    margin-top: 0px;
  }
  @include sm {
    margin-top: 84px;
  }
  .grid-container {
    padding-bottom: 24px;
    @include xs {
      width: 90%; /* We set a smaller width on forms */
    }
    @include sm {
      width: 70%; /* We set a smaller width on forms */
    }
  }
  h1 {
    opacity: 0.95;
    font-family: $font-title;
    font-size: 32px;
    line-height: 32px;
    color: $color-black;
    letter-spacing: 1.98px;
    position: relative;
    padding-left: 32px;
    text-transform: uppercase;
    &:before {
      top: 0px;
      left: 0;
      content: '';
      white-space: pre;
      position: absolute;
      width: 16px;
      height: 36px;
      background: linear-gradient(
        45deg,
        $color-orange-gradient-1 0%,
        $color-orange-gradient-2 100%
      );
      transform: skewY(-30deg) rotate(30deg);
      border-radius: 4px;
    }
  }

  h2 {
    opacity: 0.95;
    font-family: $font-title;
    font-size: 24px;
    line-height: 24px;
    color: $color-black;
    letter-spacing: 0.98px;
    padding-bottom: 1em;
    padding-top: 2em;
    padding-left: 0;
    text-transform: uppercase;
    &:before {
      display: none;
    }
  }
  h3 {
    opacity: 0.95;
    font-family: $font-title;
    font-size: 18px;
    line-height: 18px;
    color: $color-black;
    letter-spacing: 0.98px;
    padding-top: 2em;
    padding-left: 0;
    text-transform: uppercase;
    &:before {
      display: none;
    }
  }
  ul {
    list-style-type: '- ';
  }
  p,
  li {
    font-family: $font-body;
    font-size: 16px;
    opacity: 0.9;
    margin-bottom: 1em;
    em {
      font-weight: bold;
    }
  }
}
