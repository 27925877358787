@import '../../../scss/utils/mixins.scss';
@import '../../../scss/var/colors.scss';
@import '../../../scss/var/screensizes.scss';
@import '../../../scss/var/typography.scss';

#studies {
  @include xs {
    background-image: url('studies_asset_mobile.png');
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: contain;
    padding-bottom: 423px;
  }
  @include md {
    background-image: url('studies_asset.png');
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: contain;
    padding-bottom: 0;
  }
  .content {
    min-height: 90vh;
    @include xs {
      grid-column: 12 span;
    }
    @include sm {
      grid-column: 10 span;
    }
    @include md {
      grid-column: 5 span;
    }
    @include lg {
      grid-column: 6 span;
    }
    @include xl {
      grid-column: 7 span;
    }
  }
}

.studies {
  min-height: auto;
  background-image: linear-gradient(
    -60deg,
    $color-grey-gradient-1 0%,
    $color-grey-gradient-2 100%
  );
  h3 {
    color: $color-white;
    text-align: center;
  }
  &_feed {
    position: relative;
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    margin-left: -24;
    &:before {
      content: '';
      position: absolute;
      width: 3px;
      height: 100%;
      left: calc(50%);
      background-color: $color-orange;
      border-radius: 3px;
    }
  }
  &_column {
    padding-left: 24px;
    background-clip: padding-box;
  }
  .btnGroup {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
