@import '../../../scss/utils/getter.scss';
@import '../../../scss/utils/mixins.scss';
@import '../../../scss/var/colors.scss';
@import '../../../scss/var/screensizes.scss';
@import '../../../scss/var/transitions.scss';
@import '../../../scss/var/typography.scss';

@import 'MenuBtn.scss';
@import 'Nav.scss';

header {
  height: 60px;
  z-index: 900;
  left: 0;
  top: 0;
  transition-duration: get-animation(speed, controllers);
  transition-timing-function: get-animation(ease, default);
  width: 100%;
  @include xs {
    position: relative;
  }
  @include md {
    position: fixed;
  }
  .grid-container {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: space-between;
    @include xs {
      width: 90%;
    }
    @include md {
      width: 70%;
    }
    .title {
      height: 40px;
      overflow: hidden;
      .logo {
        display: flex;
        width: 70px;
      }
    }
  }
  .signup {
    background: linear-gradient(
      45deg,
      $color-orange-gradient-1 0%,
      $color-orange-gradient-2 100%
    );
    display: flex;
    line-height: 60px;
    border-radius: 0;
    color: $color-white;
    font-family: $font-body;
    font-size: 12px;
    font-weight: 700;
    height: 100%;
    letter-spacing: 3.33px;
    opacity: 0;
    padding: 0 28px;
    position: absolute;
    right: 0;
    text-align: center;
    text-transform: uppercase;
    top: 0;
    transition: all;
    transition-duration: get-animation(speed, controllers);
    transition-timing-function: get-animation(ease, default);
    pointer-events: none;
    @include xs {
      display: none;
    }
    @include md {
      display: flex;
    }
  }
  .fbBtn {
    width: 1.7em;
    height: 1.7em;
    line-height: 2em;
    color: $color-black;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: 2px solid $color-black;
    @include xs {
      display: flex;
    }
    @include md {
      display: none;
    }
  }
}

.scrolled {
  background: $color-white;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.16);
  .signup {
    opacity: 1;
    pointer-events: auto;
    &:hover {
      opacity: 0.9;
      cursor: pointer;
    }
  }
}
