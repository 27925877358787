@import '../../scss/utils/getter.scss';
@import '../../scss/utils/mixins.scss';
@import '../../scss/var/colors.scss';
@import '../../scss/var/screensizes.scss';
@import '../../scss/var/typography.scss';

#signin {
  @include xs {
    margin-top: 0px;
  }
  @include sm {
    margin-top: 84px;
  }
  .grid-container {
    padding-bottom: 24px;
    @include xs {
      width: 90%; /* We set a smaller width on forms */
    }
    @include sm {
      width: 70%; /* We set a smaller width on forms */
    }
  }
  .userInfo,
  .radio {
    @include xs {
      grid-column: 12 span;
    }
    @include sm {
      grid-column: 6 span;
    }
    h3 {
      padding-bottom: 0;
    }
    > * {
      margin-bottom: 2em;
    }
    input[type='date']::before {
      content: attr(data-placeholder);
      width: 100%;
    }
    /* hide our custom/fake placeholder text when in focus to show the default
 * 'mm/dd/yyyy' value and when valid to show the users' date of birth value.
 */
    input[type='date']:focus::before,
    input[type='date']:valid::before {
      display: none;
    }

    &__default {
      width: 100%;
      display: inline-flex;
    }
    &__gender {
      position: relative;
      div {
        width: 100%;
        display: inline-flex;
        label {
          flex: 1;
          &:last-child {
            margin-left: 1em;
          }
        }
      }
      &__error {
        font-size: 12px;
        padding-left: 1em;
        color: $color-red;
        position: absolute;
        bottom: -1.6em;
        left: 0;
        display: inline-flex;
      }
    }
    &__location {
      width: 100%;
      display: inline-flex;
      &__cp {
        margin-left: 1em;
        flex: 1;
      }
      &__city {
        flex: 3;
      }
    }
  }
  .disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  .terms {
    display: flex;
    align-items: center;
    margin-bottom: 0.5em;
    p {
      padding-left: 1em;
      font-size: 15px;
      line-height: 1.35em;
      margin: 0;
      cursor: pointer;
      user-select: none;
      a {
        text-decoration: underline;
        color: inherit;
      }
    }
  }
  .submit {
    display: inline-flex;
    justify-content: center;
  }
}

#confirm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  i {
    width: 2em;
    height: 2em;
    text-align: center;
    line-height: 2em;
    border: 4px solid $color-orange;
    border-radius: 50%;
    font-size: 3em;
    color: $color-orange;
  }
  h2 {
    &:before {
      display: none;
    }
    &:first-letter {
      text-transform: uppercase;
    }
    margin-top: 1em;
    margin-bottom: 1em;
    text-transform: lowercase;
    padding-left: 0;
  }
}

#failed {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  i {
    font-size: 3em;
    color: $color-red;
  }
  h2 {
    &:before {
      display: none;
    }
    &:first-letter {
      text-transform: uppercase;
    }
    margin-top: 1em;
    margin-bottom: 1em;
    text-transform: lowercase;
    padding-left: 0;
  }
}
