@import '../../../scss/utils/getter.scss';
@import '../../../scss/var/colors.scss';
@import '../../../scss/var/transitions.scss';

.big-input {
  margin: 16px 0;
  .input__container {
    width: 100%;
    max-width: 100%;
  }
}

.input {
  &__container {
    display: inline-flex;
    align-items: center;
    position: relative;
    height: 48px;
    input {
      width: 100%;
      box-sizing: border-box;
      height: 100%;
      outline: none;
      outline-width: 0;
      padding-left: 16px;
      padding-right: 42px;
      border-radius: 4px;
      font-size: 14px;
      -webkit-appearance: none;
      color: $color-black;
      transition: background-color get-animation(speed, controllers)
          get-animation(ease, default),
        border get-animation(speed, controllers) get-animation(ease, default),
        padding get-animation(speed, controllers) get-animation(ease, default);
    }

    &--default {
      input {
        border: solid 1px transparent;
        background-color: mix($color-light-grey, $color-white, 50%);
        &:hover {
          background-color: mix($color-light-grey, $color-white, 40%);
        }

        &:focus {
          outline: none;
          outline-width: 0;
          background-color: $color-white;
          border: solid 2px $color-orange;
          padding: 0px 41px 0px 15px;
        }
      }

      &-filled input {
        border: solid 1px $color-mid-grey;
        background-color: $color-white !important;

        &:hover {
          border: solid 1px rgb(111, 111, 111);
        }
      }

      .label--focused {
        color: $color-orange;
      }
    }

    &--error {
      input {
        border: solid 2px $color-red;
      }

      .label--focused {
        color: $color-red;
      }
    }

    &--disabled {
      input {
        background-color: rgb(246, 246, 246);
        cursor: not-allowed;
        user-select: none;
        border: solid 1px $color-mid-grey;

        &:hover {
          border: solid 1px rgb(111, 111, 111);
        }
      }

      label,
      input {
        color: $color-mid-grey;
      }
    }

    .label {
      &--default,
      &--focused {
        text-overflow: ellipsis;
        user-select: none;
        pointer-events: none;
        left: 8px;
        line-height: 2px;
        height: 2px;
        padding: 0 8px;
        position: absolute;
        transition: top get-animation(speed, controllers)
            get-animation(ease, default),
          color get-animation(speed, controllers) get-animation(ease, default),
          background-color get-animation(speed, controllers)
            get-animation(ease, default),
          font-size get-animation(speed, controllers)
            get-animation(ease, default);
      }

      &--default {
        color: $color-mid-grey;
        top: 50%;
        transform: translateY(-50%);
        font-size: 14px;
        background-color: transparent;
      }

      &--focused {
        top: 0;
        font-size: 14px;
        background-color: $color-white;
      }
    }

    .color-disabled {
      color: $color-mid-grey;
    }

    .helper {
      &--display,
      &--default,
      &--error,
      &--disabled {
        user-select: none;
        position: absolute;
        font-size: 12px;
        bottom: -16px;
        left: 16px;
        transition: opacity get-animation(speed, controllers)
            get-animation(ease, default),
          color get-animation(speed, controllers) get-animation(ease, default);
      }

      &--default {
        color: $color-mid-grey;
        opacity: 0;
      }

      &--display {
        color: $color-orange;
        opacity: 1;
      }

      &--error {
        color: $color-red;
      }

      &--disabled {
        color: $color-mid-grey;
      }
    }

    .input-icon {
      font-size: 16px;
      line-height: 48px;
      user-select: none;
      pointer-events: none;
      position: absolute;
      right: 22px;
      color: $color-mid-grey;

      &--active {
        color: $color-orange;
      }
    }

    .clear-content-icon {
      line-height: 14px;
      font-size: 14px;
      color: $color-mid-grey;
      cursor: pointer;
      pointer-events: all;
    }
  }
}

.select-filled {
  padding: 5px;
}
