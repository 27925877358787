@import '../../../scss/utils/mixins.scss';
@import '../../../scss/var/colors.scss';
@import '../../../scss/var/screensizes.scss';
@import '../../../scss/var/typography.scss';

#home {
  padding-top: 0;
  min-height: calc(100vh - 60px);
  display: flex;
  @include xs {
    align-items: flex-start;
    background: url('home_asset_mobile.png');
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: contain;
    padding-bottom: 180px;
  }
  @include md {
    align-items: center;
    background-image: url('home_asset.png');
    @media (-webkit-min-device-pixel-ratio: 2) {
      background-image: url('home_asset@2x.png');
    }
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: contain;
    padding-bottom: 60px;
  }
  .main {
    @include xs {
      padding-top: 16px;
      grid-column: 12 span;
    }
    @include sm {
      grid-column: 10 span;
    }
    @include md {
      padding-top: 60px;
      grid-column: 9 span;
    }
    @include lg {
      grid-column: 7 span;
    }
    @include xl {
      grid-column: 7 span;
    }
    @include xs {
      text-align: center;
    }
    @include md {
      text-align: left;
    }
    .title {
      &:before {
        display: none;
      }
      padding-left: 0;
      width: 100%;
      color: $color-black;
      font-family: $font-title;
      letter-spacing: 0.04em;
      line-height: 1.2em;
      @include xs {
        font-size: 2.5em;
        padding-bottom: 22px;
      }
      @include sm {
        font-size: 3.5em;
        padding-bottom: 40px;
      }
      em {
        font-weight: 600;
        color: $color-orange;
      }
    }
    .abstract {
      padding-bottom: 32px;
      font-family: $font-body;
      font-size: 1.1em;
      line-height: 2em;
      color: $color-mid-grey;
      text-transform: none;
      letter-spacing: initial;
    }
  }

  .mice {
    position: absolute;
    width: 22px;
    height: 42px;
    bottom: 40px;
    left: 50%;
    margin-left: -12px;
    border-radius: 15px;
    border: 2px solid $color-mid-grey;
    @include xs {
      display: none;
    }
    @include md {
      display: block;
    }
  }
  .scroll {
    display: block;
    width: 3px;
    height: 3px;
    margin: 6px auto;
    border-radius: 4px;
    background: $color-mid-grey;
    animation: finger 2.5s infinite;
  }
}
