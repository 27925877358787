@import '../../../scss/utils/getter.scss';
@import '../../../scss/var/colors.scss';
@import '../../../scss/var/transitions.scss';

.Selector {
  position: relative;
  user-select: none;
  border-radius: 3px;
  color: $color-mid-grey;
  background-color: $color-white;
  border: solid 1px $color-mid-grey;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  padding: 12px;
  min-height: 24px;
  transition: border-color get-animation(speed, controllers)
      get-animation(ease, default),
    color get-animation(speed, controllers) get-animation(ease, default),
    background get-animation(speed, controllers) get-animation(ease, default);
}

.Selector:hover:not(.Selector--disabled) {
  opacity: 0.8;
}

.Selector--focus {
  opacity: 0.7;
}

.Selector--checked {
  padding: #{12px - 1px} #{12px - 1px};
  color: $color-orange;
  border: solid 2px $color-orange;
}

.Selector--disabled {
  opacity: 0.5;
  background-color: $color-light-grey;
  filter: grayscale(1);
}

.Selector__nativeInput {
  margin: 0;
  border: none;
  min-width: 0;
  padding: 0;
  background: none;
  color: inherit;
  font: inherit;
  outline: none;
  position: absolute;
  opacity: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
}

.Selector__nativeInput:disabled {
  cursor: not-allowed;
}

.Selector__mark {
  position: absolute;
  top: 0;
  right: 0;
  height: 40px;
  width: 40px;
  pointer-events: none;
}

.Selector__mark::before {
  transition: border-top-color get-animation(speed, controllers)
    get-animation(ease, default);
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-top: 40px solid transparent;
  border-left: 40px solid transparent;
}

.Selector__mark--checked::before {
  border-top-color: $color-orange;
}

.Selector__mark__icon {
  position: absolute;
  top: 7px;
  right: 6px;
}

.Selector__mark__icon--checked {
  top: #{7px - 1px};
  right: #{6px - 1px};
}

.Selector__mark__icon--radio {
  color: $color-mid-grey;
  transition: color get-animation(speed, controllers)
    get-animation(ease, default);
}

.Selector__mark__icon--radio.Selector__mark__icon--checked {
  color: $color-white;
}

.Selector__mark__icon--checkbox {
  color: $color-white;
  // The checkbox icon is only visible when it is checked.
  opacity: 0;
  transition: opacity get-animation(speed, controllers)
    get-animation(ease, default);
}

.Selector__mark__icon--checkbox.Selector__mark__icon--checked {
  opacity: 1;
}

.Selector__label {
  max-width: 100%;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
