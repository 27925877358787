@import '../../scss/utils/getter.scss';
@import '../../scss/utils/mixins.scss';
@import '../../scss/var/colors.scss';
@import '../../scss/var/screensizes.scss';
@import '../../scss/var/typography.scss';

#signout {
  @include xs {
    margin-top: 0px;
  }
  @include sm {
    margin-top: 84px;
  }
  .grid-container {
    padding-bottom: 24px;
    @include xs {
      width: 90%; /* We set a smaller width on forms */
    }
    @include sm {
      width: 70%; /* We set a smaller width on forms */
    }
  }
  .content {
    @include xs {
      grid-column: 12 span;
    }
    @include sm {
      grid-column: 6 span;
    }
    padding-top: 1em;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    p {
      @include xs {
        width: 90%;
      }
      @include sm {
        width: 50%;
      }
    }
    .userEmail {
      @include xs {
        width: 90%;
      }
      @include sm {
        width: 50%;
      }
    }
    .btnGroup {
      margin-top: 3em;
    }
  }
}
