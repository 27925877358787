@import '../../scss/utils/getter.scss';
@import '../../scss/utils/mixins.scss';
@import '../../scss/var/colors.scss';
@import '../../scss/var/screensizes.scss';
@import '../../scss/var/transitions.scss';
@import '../../scss/var/typography.scss';

.btnGroup {
  @include xs {
    display: flex;
    flex-direction: column;
    align-items: center;
    & > :not(:first-child) {
      margin-top: 1em;
    }
  }
  @include sm {
    margin: 32px 0;
    display: inline-flex;
    flex-direction: row;
    & > :not(:first-child) {
      margin-left: 32px;
      margin-top: 0;
    }
  }
}

.btn {
  border: none;
  padding: 16px 32px;
  border-radius: 35px;
  text-overflow: ellipsis;
  white-space: nowrap;
  align-self: center;
  align-items: center;
  outline: none;
  user-select: none;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  transition-property: border, background-color, color, opacity;
  transition-duration: get-animation(speed, controllers);
  transition-timing-function: get-animation(ease, default);
  &,
  &__label {
    color: $color-white;
    font-family: $font-body;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 3.33px;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
  }
  &__icon {
    margin-right: 0.5em;
  }
  &--primary {
    background: linear-gradient(
      45deg,
      $color-orange-gradient-1 0%,
      $color-orange-gradient-2 100%
    );
    &-disabled {
      filter: saturate(0.75);
      opacity: 0.5 !important;
      pointer-events: none;
      cursor: inherit;
    }
  }
  &--red {
    background: $color-red;
  }
  &--fb {
    background: $color-fb;
  }
  &:hover {
    opacity: 0.9;
  }
}
