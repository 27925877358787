@import '../../../scss/utils/mixins.scss';
@import '../../../scss/var/colors.scss';
@import '../../../scss/var/screensizes.scss';
@import '../../../scss/var/typography.scss';

footer {
  background: $color-light-grey;
  z-index: 800;
  .grid-container {
    align-items: center;
    padding: 24px 0;
    @include xs {
      width: 100%;
    }
    @include md {
      width: 60%;
    }
    .col-4 {
      @include xs {
        grid-column: 12 span;
        text-align: center;
      }
      @include md {
        grid-column: 4 span;
        text-align: left;
      }
    }
    .logo {
      text-align: center;
      width: 167px;
    }
    nav {
      display: flex;
      flex-direction: column;
      @include xs {
        align-items: center;
      }
      @include md {
        align-items: flex-start;
      }
      h3 {
        color: $color-black;
        font-family: $font-title;
        font-size: 18px;
        letter-spacing: 0.74px;
        padding: 10px 0;
      }
      .nav__item {
        color: $color-mid-grey;
        cursor: pointer;
        font-family: $font-body;
        font-size: 14px;
        line-height: 2.5em;
        font-weight: 600;
        padding: 10px 0;
      }
    }
  }
  .legal {
    border-top: 1px solid #d4d4d4;
    color: $color-mid-grey;
    font-family: $font-body;
    font-size: 14px;
    text-align: center;
    width: 100%;
    @include xs {
      padding-top: 24px;
      padding-bottom: 10em;
    }
    @include md {
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }
}
