$paddingTop: 84px;
$paddingBottom: 60px;

section {
  width: 100vw;
  @include xs {
    min-height: calc(100vh - #{$paddingTop}/ 2 - #{$paddingBottom}/ 2);
    padding-top: $paddingTop / 2;
    padding-bottom: $paddingBottom / 2;
  }
  @include sm {
    min-height: calc(100vh - #{$paddingTop} - #{$paddingBottom});
    padding-top: $paddingTop;
    padding-bottom: $paddingBottom;
  }
}
