@import '../../scss/utils/getter.scss';
@import '../../scss/utils/mixins.scss';
@import '../../scss/var/screensizes.scss';
@import '../../scss/var/typography.scss';

#contact {
  @include xs {
    margin-top: 0px;
  }
  @include sm {
    margin-top: 84px;
  }
  .grid-container {
    padding-bottom: 24px;
    @include xs {
      width: 90%; /* We set a smaller width on forms */
    }
    @include sm {
      width: 70%; /* We set a smaller width on forms */
    }
  }
  .content {
    @include xs {
      grid-column: 12 span;
    }
    @include sm {
      grid-column: 6 span;
    }
  }
  .form {
    @include xs {
      grid-column: 12 span;
    }
    @include sm {
      grid-column: 6 span;
    }
    > * {
      margin-bottom: 1em;
    }
    &__default {
      width: 100%;
      display: inline-flex;
    }
  }
}
