@import '../../../scss/utils/getter.scss';
@import '../../../scss/var/colors.scss';
@import '../../../scss/var/transitions.scss';

@function Checkbox-border($width, $color) {
  @return inset 0 0 0 $width $color;
}

.Checkbox {
  position: relative;
  width: 16px;
  min-width: 16px;
  height: 16px;
  border-radius: 2px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  box-shadow: Checkbox-border(1px, $color-mid-grey);
  background-color: $color-white;
  transition: background-color get-animation(speed, controllers)
      get-animation(ease, default),
    box-shadow get-animation(speed, controllers) get-animation(ease, default);
}

.Checkbox--checked {
  box-shadow: Checkbox-border(1px, $color-orange);
  background-color: $color-orange;
  color: $color-white;
}

.Checkbox--disabled {
  box-shadow: Checkbox-border(1px, $color-light-grey);
}

.Checkbox--disabled.Checkbox--checked {
  background-color: $color-light-grey;
}

.Checkbox__nativeInput {
  margin: 0;
  border: none;
  min-width: 0;
  padding: 0;
  background: none;
  color: inherit;
  font: inherit;
  outline: none;
  position: absolute;
  opacity: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
}

.Checkbox__nativeInput:disabled {
  cursor: not-allowed;
}

.Checkbox__nativeInput[readonly],
.Checkbox__nativeInput:read-only {
  cursor: inherit;
}
