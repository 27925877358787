@import '../../../scss/utils/mixins.scss';
@import '../../../scss/var/colors.scss';
@import '../../../scss/var/screensizes.scss';
@import '../../../scss/var/typography.scss';

#gains {
  position: relative;
  .fluid_bg {
    z-index: 0;
    height: 100%;
    width: 100%;
    top: 0;
    position: absolute;
    background-image: url('bg_asset.png');
    @include xs {
      display: none;
    }
    @include md {
      display: block;
      background-position: -10vw center;
      background-repeat: no-repeat;
      background-size: contain;
    }
    @include xl {
      background-position: -15vw center;
      background-repeat: no-repeat;
      background-size: contain;
    }
    @include xxl {
      background-position: -5vw center;
      background-repeat: no-repeat;
      background-size: contain;
    }
    &__girl {
      position: absolute;
      width: 100%;
      height: 100%;
      bottom: 0;
      max-height: 900px;
      background-image: url('girl_asset.png');
      @include md {
        background-position: -65% bottom;
        background-repeat: no-repeat;
        background-size: 60%;
      }
      @include lg {
        background-position: -25vw bottom;
        background-repeat: no-repeat;
        background-size: contain;
      }
      @include xl {
        background-position: -24vw bottom;
        background-repeat: no-repeat;
        background-size: contain;
      }
      @include xxl {
        background-position: -20vw bottom;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
  }
  .spacer {
    @include xs {
      display: none;
    }
    @include md {
      display: block;
    }
  }
  h2 {
    @include xs {
      grid-column: 12 span;
    }
    @include md {
      grid-column: 9 span;
    }
  }
  .content {
    z-index: 200;
    @include xs {
      grid-column: 12 span;
    }
    @include md {
      grid-column: 5 span;
    }
  }
  .feed {
    position: relative;
    z-index: 200;
    @include xs {
      grid-column: 12 span;
    }
    @include md {
      grid-column: 4 span;
    }
    &__wrapper {
      max-height: calc(100vh - 270px);
      overflow-y: scroll;
      padding-top: 2px;
      &:before {
        content: '';
        position: absolute;
        width: 3px;
        height: 100%;
        left: 9px;
        background-color: $color-orange;
        border-radius: 3px;
      }
    }
    @include md {
      &:after {
        content: '';
        position: absolute;
        bottom: -2px;
        left: -4px;
        width: 100%;
        background: linear-gradient(0deg, $color-white 0%, transparent 100%);
        height: 90px;
      }
    }
  }
}
