@import '../../../../scss/utils/mixins.scss';
@import '../../../../scss/var/colors.scss';
@import '../../../../scss/var/screensizes.scss';
@import '../../../../scss/var/typography.scss';

.studies_column {
  @include sm {
    &:nth-child(even) {
      .studies_column_study {
        &:first-child {
          margin-top: 2em;
        }
        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: -33px;
          height: 12px;
          width: 12px;
          background: $color-orange;
          display: block;
          border-radius: 100%;
          border: 4px solid $color-grey-gradient-2;
        }
      }
    }
    &:nth-child(odd) {
      .studies_column_study {
        &:before {
          content: '';
          position: absolute;
          top: 0;
          right: -35px;
          height: 12px;
          width: 12px;
          background: $color-orange;
          display: block;
          border-radius: 100%;
          border: 4px solid $color-grey-gradient-2;
        }
      }
    }
  }
  &_study {
    box-sizing: border-box;
    font-family: $font-body;
    background: $color-white;
    border-radius: 4px;
    padding: 18px 24px;
    width: calc(100% - 24px);
    position: relative;
    margin-bottom: 2em;
    display: flex;
    flex-direction: column;
    &__post {
      font-size: 16px;
      color: $color-black;
      line-height: 24px;
      p {
        margin-bottom: 1em;
      }
      a {
        color: $color-orange;
        font-weight: 600;
        border-bottom: 1px $color-orange dashed;
      }
    }
    &__date {
      width: 100%;
      font-size: 14px;
      color: $color-mid-grey;
      text-align: right;
      padding-bottom: 8px;
    }
    &__media {
      outline: none;
      padding: 8px 0;
      object-fit: cover;
      width: 100%;
    }
    &__link {
      padding-top: 12px;
      border-top: 1px solid $color-light-grey;
      font-size: 16px;
      color: $color-mid-grey;
      letter-spacing: 0.2px;
      text-align: center;
      font-weight: 600;
      cursor: pointer;
      display: block;
      width: 100%;
    }
  }
}
