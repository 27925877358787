/*Colors*/
/* Utils*/
$color-black: rgb(36, 39, 44);
$color-white: rgb(255, 255, 255);
$color-fb: #3c5a99;

/* Main*/
$color-mid-grey: rgb(124, 125, 128);
$color-light-grey: rgb(230, 230, 230);
$color-orange: rgb(253, 161, 46);
$color-red: rgb(255, 44, 44);

/* Gradients*/
$color-orange-gradient-1: rgb(255, 168, 42);
$color-orange-gradient-2: rgb(255, 145, 58);
$color-grey-gradient-1: rgb(56, 59, 53);
$color-grey-gradient-2: rgb(36, 39, 44);
