@import '../../../scss/utils/getter.scss';
@import '../../../scss/var/colors.scss';
@import '../../../scss/var/transitions.scss';

.big-input {
  margin: 16px 0;
  .textarea__container {
    width: 100%;
    max-width: 100%;
  }
}

.textarea {
  &__container {
    display: inline-flex;
    align-items: center;
    position: relative;
    // height: 48px;
    textarea {
      resize: none;
      width: 100%;
      box-sizing: border-box;
      height: calc(100% - 24px);
      outline: none;
      padding-left: 16px;
      padding-right: 42px;
      border-radius: 4px;
      font-size: 14px;
      color: $color-black;
      transition: background-color get-animation(speed, controllers)
          get-animation(ease, default),
        border get-animation(speed, controllers) get-animation(ease, default),
        padding get-animation(speed, controllers) get-animation(ease, default);
    }

    &--default {
      textarea {
        border: solid 1px transparent;
        background-color: mix($color-light-grey, $color-white, 50%);

        &:hover {
          background-color: mix($color-light-grey, $color-white, 40%);
        }

        &:focus {
          background-color: $color-white;
          border: solid 2px $color-orange;
          padding: 12px 41px 0px 15px;
        }
      }

      &-filled textarea {
        border: solid 1px $color-mid-grey;
        background-color: $color-white !important;

        &:hover {
          border: solid 1px rgb(111, 111, 111);
        }
      }

      .label--focused {
        color: $color-orange;
      }
    }

    &--error {
      textarea {
        border: solid 2px $color-red;
      }

      .label--focused {
        color: $color-red;
      }
    }

    &--disabled {
      textarea {
        background-color: rgb(246, 246, 246);
        cursor: not-allowed;
        user-select: none;
        border: solid 1px $color-mid-grey;
        &:hover {
          border: solid 1px rgb(111, 111, 111);
        }
      }

      label,
      textarea {
        color: $color-mid-grey;
      }
    }

    .label {
      &--default,
      &--focused {
        text-overflow: ellipsis;
        user-select: none;
        pointer-events: none;
        top: 8px;
        left: 8px;
        line-height: 2px;
        height: 2px;
        padding: 0 8px;
        position: absolute;
        transition: top get-animation(speed, controllers)
            get-animation(ease, default),
          color get-animation(speed, controllers) get-animation(ease, default),
          background-color get-animation(speed, controllers)
            get-animation(ease, default),
          font-size get-animation(speed, controllers)
            get-animation(ease, default);
      }

      &--default {
        color: $color-mid-grey;
        // top: 50%;
        top: 24px;
        transform: translateY(-50%);
        font-size: 14px;
        background-color: transparent;
      }

      &--focused {
        top: 0;
        font-size: 12px;
        background-color: $color-white;
      }
    }

    .color-disabled {
      color: $color-mid-grey;
    }

    .helper {
      &--display,
      &--default,
      &--error,
      &--disabled {
        user-select: none;
        position: absolute;
        font-size: 12px;
        bottom: -16px;
        left: 16px;
        transition: opacity get-animation(speed, controllers)
            get-animation(ease, default),
          color get-animation(speed, controllers) get-animation(ease, default);
      }

      &--default {
        color: $color-mid-grey;
        opacity: 0;
      }

      &--display {
        color: $color-orange;
        opacity: 1;
      }

      &--error {
        color: $color-red;
      }

      &--disabled {
        color: $color-mid-grey;
      }
    }

    .input-icon {
      font-size: 16px;
      line-height: 48px;
      user-select: none;
      pointer-events: none;
      position: absolute;
      right: 22px;
      color: $color-mid-grey;

      &--active {
        color: $color-orange;
      }
    }

    .clear-content-icon {
      line-height: 14px;
      font-size: 14px;
      color: $color-mid-grey;
      cursor: pointer;
      pointer-events: all;
    }
  }
}

.select-filled {
  padding: 5px;
}
